export const translations = {
    "Upload URL might be wrong or doesn't exist.":"Upload URL might be wrong or doesn't exist.",
    "Init error.":"Init error.",
    "%s specified, but cannot be found.":"%s specified, but cannot be found.",
    "Image format either wrong or not supported.":"Image format either wrong or not supported.",
    "HTTP Error.":"HTTP Error.",  // Raised when there was a connection issue between the client and the server
    "Error: File too large:":"Error: File too large:",
    "Duplicate file error.":"Duplicate file error.",
    "File size error.":"File size error.",  // Raised when the provided file is empty or too large
    "N/A":"N/A",
    "Error: Invalid file extension:":"Error: Invalid file extension:",
    "%s already present in the queue.":"%s already present in the queue.",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"Upload element accepts only %d file(s) at a time. Extra files were stripped.",
    "Runtime ran out of available memory.":"Runtime ran out of available memory.",
    "File count error.":"File count error.",
    "File extension error.":"File extension error.",  // Raised when the provided file has an unsupported extension
}
