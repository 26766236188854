export const translations = {
    "Upload URL might be wrong or doesn't exist.":"O URL para carregar os ficheiros pode estar errado ou não existir.",
    "Init error.":"Erro ao iniciar.",
    "%s specified, but cannot be found.":"%s definido mas não foi encontrado.",
    "Image format either wrong or not supported.":"Formato da imagem errado ou não é suportado.",
    "HTTP Error.":"Erro HTTP.",
    "Error: File too large:":"Erro: Ficheiro demasiado grande:",
    "Duplicate file error.":"Erro: ficheiro duplicado.",
    "File size error.":"Tamanho do ficheiro errado.",
    "N/A":"N/D",
    "Error: Invalid file extension:":"Erro: Extensão de ficheiro inválida:",
    "%s already present in the queue.":"%s já se encontra em fila.",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"Resolução excede os limites! <b>%s</b> suporta imagens até %wx%hpx.",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"Só é possível enviar %d ficheiro(s) de cada vez. Os restantes ficheiros foram excluídos.",
    "Runtime ran out of available memory.":"A execução esgotou a memória disponível.",
    "File count error.":"Erro: contagem de ficheiros.",
    "File extension error.":"Error de extensão do ficheiro.",
}
