import $ from 'jQuery'
import ko from 'knockout'
import { uploadLocation } from './locations'
import { uploadTranslations } from './translations'

var S3Uploader = (function () {
    function S3Uploader(options) {
        this.options = options || {};
        // https://www.plupload.com/docs/v2/Options
        this.uploadOptions = {
            runtimes: "html5",
            browse_button: this.options.browse_button || "pickfiles",
            container: this.options.container || "upload-container",
            drop_element: this.options.drop_element || "drop-target",
            filters: this.options.filters || {},
            multi_selection: false,
            url: "", // set when the file settings are retrieved
            multipart_params: {
                acl: "private"
            }
        };

        if (!this.options.allow_drag_and_drop) {
            // If the specified drop_element is missing from the DOM the browse_button stops working
            delete this.uploadOptions['drop_element']
        }

        if (this.options.resize) {
            this.uploadOptions['resize'] = this.options.resize
        }

        ko.computed(function () {
            plupload.addI18n(uploadTranslations[ko.unwrap(this.options.language)] || uploadTranslations['en']);
        }.bind(this));

        this.plup = new plupload.Uploader(this.uploadOptions);

        if (this.options.PostInit) {
            this.plup.bind("PostInit", this.options.PostInit);
        }

        if (this.options.FileFiltered) {
            this.plup.bind("FileFiltered", this.options.FileFiltered);
        }

        this.plup.bind("BeforeUpload", this.onBeforeUpload.bind(this));
        if (this.options.BeforeUpload) {
            this.plup.bind("BeforeUpload", this.options.BeforeUpload);
        }

        this.plup.bind("FilesAdded", this.onFilesAdded.bind(this));
        if (this.options.FilesAdded) {
            this.plup.bind("FilesAdded", this.options.FilesAdded);
        }

        if (this.options.UploadProgress) {
            this.plup.bind("UploadProgress", this.options.UploadProgress);
        }

        if (this.options.FileUploaded) {
            this.plup.bind("FileUploaded", this.options.FileUploaded);
        }

        if (this.options.Error) {
            this.plup.bind("Error", this.options.Error);
        }

        if (this.options.FilesRemoved) {
            this.plup.bind("FilesRemoved", this.options.FilesRemoved);
        }

        this.plup.init();
    }

    S3Uploader.prototype.getUploadSettings = function(filename) {
        var url = uploadLocation + "/api/settings/" + encodeURI(filename)
        //TODO: ensure subfolder option ends with '/' if presented
        if (this.options.subfolder && typeof(this.options.subfolder) === 'string') {
            url += '?subfolder=' + encodeURIComponent(this.options.subfolder)
        }

        if (this.options.addTimestampToKey) {
            url += (url.indexOf('?') === -1 ? '?' : '&') + 'add_timestamp_to_key=1'
        }

        return $.ajax({
            type: "GET",
            url: url,
            contentType: "application/json",
            dataType: "json",
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            beforeSend: function(xhr) {
                xhr["withCredentials"] = true;
            }
        });
    };

    S3Uploader.prototype.onBeforeUpload = function(up, file) {
        up.settings.multipart_params["Content-type"] = file.type;
        up.settings.multipart_params["AWSAccessKeyId"] = file.aws_settings.fields["AWSAccessKeyId"];
        up.settings.multipart_params["x-amz-security-token"] = file.aws_settings.fields["x-amz-security-token"];
        up.settings.multipart_params["signature"] = file.aws_settings.fields["signature"];
        up.settings.multipart_params["policy"] = file.aws_settings.fields["policy"];
        up.settings.multipart_params["acl"] = file.aws_settings.fields["acl"];
        up.settings.multipart_params["key"] = file.aws_settings.fields["key"];
        up.setOption('url', file.aws_settings.url);
    };

    S3Uploader.prototype.onFilesAdded = function(up, files) {
        if (this.options.autostart) {
           this.start();
        }
    };

    S3Uploader.prototype.start = function() {
        var promises = this.plup.files.map(function(file){
            file.settingsPromise = this.getUploadSettings(file.name).done(function(result) {
                file.aws_settings = result;
            }.bind(this));

            return file.settingsPromise;
        }.bind(this));

        $.when.apply($, promises).done(function() {
            this.plup.start();
        }.bind(this));
    };

    return S3Uploader;
})();

export { S3Uploader }
