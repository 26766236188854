export const translations = {
    "Upload URL might be wrong or doesn't exist.":"URL di Upload errata o non esistente",
    "Init error.":"Errore inizializzazione.",
    "%s specified, but cannot be found.":"%s specificato, ma non è stato possibile trovarlo.",
    "Image format either wrong or not supported.":"Formato immagine errato o non supportato.",
    "HTTP Error.":"Errore HTTP.",
    "Error: File too large:":"Errore: File troppo grande:",
    "Duplicate file error.":"Errore file duplicato.",
    "File size error.":"Errore dimensione file.",
    "N/A":"N/D",
    "Error: Invalid file extension:":"Errore: Estensione file non valida:",
    "%s already present in the queue.":"%s già presente nella coda.",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"Risoluzione oltre i limiti! <b>%s</b> runtime supporta immagini fino a %wx%hpx.",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"Upload element accepts only %d file(s) at a time. Extra files were stripped.",
    "Runtime ran out of available memory.":"Runtime ha esaurito la memoria disponibile.",
    "File count error.":"File count error.",
    "File extension error.":"Errore estensione file.",
}
