export const translations = {
    "Upload URL might be wrong or doesn't exist.":"URL за качване може да е грешен или да не съществува.",
    "Init error.":"Грешка: инициализиране.",
    "%s specified, but cannot be found.":"",
    "Image format either wrong or not supported.":"Формата на изображението или е объркан, или не се поддържа.",
    "HTTP Error.":"Грешка: HTTP .",
    "Error: File too large:":"Грешка: Файла е твърде голям:",
    "Duplicate file error.":"Грешка: файла е вече качен на сървъра.",
    "File size error.":"Грешка: размер на файла.",
    "N/A":"не приложимо",
    "Error: Invalid file extension:":"Грешка: Невалидно разширение на файл:",
    "%s already present in the queue.":"%s вече го има в опашката.",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"Елемента за качване приема само %d файл(а) наведнъж. Допълнителните файлове бяха отстранени.",
    "Runtime ran out of available memory.":"Недостатъчна свободна памет.",
    "File count error.":"Грешка в броя на файловете.",
    "File extension error.":"Грешка: разширение на файла.",
}
