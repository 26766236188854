export const translations = {
    "Upload URL might be wrong or doesn't exist.":"上传的URL可能是错误的或不存在。",
    "Init error.":"初始化错误。",
    "%s specified, but cannot be found.":"%s 已指定，但是没有找到。",
    "Image format either wrong or not supported.":"图片格式错误或者不支持。",
    "HTTP Error.":"HTTP 错误。",
    "Error: File too large:":"错误: 文件太大:",
    "Duplicate file error.":"重复文件错误。",
    "File size error.":"文件大小错误。",
    "N/A":"N/A",
    "Error: Invalid file extension:":"错误：无效的文件扩展名:",
    "%s already present in the queue.":"%s 已经在当前队列里。",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"超限。<b>%s</b> 支持最大 %wx%hpx 的图片。",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"每次只接受同时上传 %d 个文件，多余的文件将会被删除。",
    "Runtime ran out of available memory.":"运行时已消耗所有可用内存。",
    "File count error.":"文件数量错误。",
    "File extension error.":"文件扩展名错误。",
}
