export const translations = {
    "Upload URL might be wrong or doesn't exist.":"Upload URL kan være forkert eller ikke eksisterende.",
    "Init error.":"Opstarts fejl.",
    "%s specified, but cannot be found.":"",
    "Image format either wrong or not supported.":"Billede format er enten forkert eller ikke understøttet.",
    "HTTP Error.":"HTTP fejl.",
    "Error: File too large:":"Fejl: Filen er for stor:",
    "Duplicate file error.":"Filen findes allerede.",
    "File size error.":"Filstørrelse fejl.",
    "N/A":"N/A",
    "Error: Invalid file extension:":"Fejl: Ugyldigt fil format:",
    "%s already present in the queue.":"%s findes allerede i køen.",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"Upload accepterer kun %d fil(er) af gangen. Ekstra filer blev skippet.",
    "Runtime ran out of available memory.":"Runtime mangler tilgængelige hukommelse.",
    "File count error.":"Fil antal fejl.",
    "File extension error.":"Fil format fejl.",
}
