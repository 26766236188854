export const translations = {
    "Upload URL might be wrong or doesn't exist.":"Upload-URL ist falsch oder existiert nicht.",
    "Init error.":"Initialisierungsfehler",
    "%s specified, but cannot be found.":"",
    "Image format either wrong or not supported.":"Bildformat falsch oder nicht unterstützt.",
    "HTTP Error.":"HTTP-Fehler",
    "Error: File too large:":"Fehler: Datei zu groß:",
    "Duplicate file error.":"Datei bereits hochgeladen",
    "File size error.":"Fehler bei Dateigröße",
    "N/A":"Nicht verfügbar",
    "Error: Invalid file extension:":"Fehler: Ungültige Dateiendung:",
    "%s already present in the queue.":"%s ist bereits in der Warteschlange",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"Pro Durchgang können nur %d Datei(en) akzeptiert werden. Überzählige Dateien wurden ignoriert.",
    "Runtime ran out of available memory.":"Nicht genügend Speicher verfügbar.",
    "File count error.":"Fehlerhafte Dateianzahl.",
    "File extension error.":"Fehler bei Dateiendung",
}
