import '../css/upload.css'
import $ from 'jQuery'
import ko from 'knockout'
import { ssoLocation, uploadLocation } from "./locations";

var UploadFileViewModel = (function() {
    function UploadFileViewModel(file) {
        this.file = file;
        this.id = file.id;
        this.name = ko.observable(file.name || "");
        this.type = ko.observable(file.type || "");
        this.size = ko.observable(file.size || "");
        this.modified = ko.observable(file.modified || "");
        this.status = ko.observable(file.status);
        this.progress = ko.observable(0);
        this.etag = ko.observable(file.etag || "");
        this.key = ko.observable(this.getKey(file));
        this.href = ko.pureComputed(this._href.bind(this));

        this.statusText = ko.pureComputed(this._statusText.bind(this));
    }

    UploadFileViewModel.prototype.cancel = function() {
    };

    UploadFileViewModel.prototype.update = function() {
        this.progress(this.file.percent);
        this.status(this.file.status);
        this.key(this.getKey(this.file));
    };

    UploadFileViewModel.prototype.getKey = function (file) {
        return file.aws_settings && file.aws_settings.fields && file.aws_settings.fields.key || "";
    }

    UploadFileViewModel.prototype._statusText = function() {
        if (this.status() === plupload.QUEUED) {
            return "Pending";
        } else if (this.status() === plupload.UPLOADING) {
            return this.progress() + "%";
        } else if (this.status() === plupload.DONE) {
            return "Success";
        } else if (this.status() === 'existing') {
            return this.modified();
        } else {
            return "Error " + this.file.status;
        }
    };

    UploadFileViewModel.prototype._href = function () {
        if (!this.key()) {
            return "";
        }

        return uploadLocation + "/api/link?key=" + this.key();
    }

    UploadFileViewModel.prototype.typeGroup = function() {
        var type = String(this.type());
        if (["application/pdf"].indexOf(type) >= 0) {
            return "pdf";
        }
        else if (type.toLowerCase().indexOf("image") === 0) {
            return "image";
        }
        else if (type.toLowerCase().indexOf("text") === 0) {
            return "text";
        }
        else if (this.name().lastIndexOf(".vwx") === (this.name().length - 4)) {
            return "vw";
        }
        else {
            return "any";
        }
    };

    return UploadFileViewModel;
})();


var UploadFilesViewModel = (function() {
    function UploadFilesViewModel(uploader, options) {
        this.uploader = uploader;
        this.uploadFiles = ko.observableArray();
        this.existingFiles = ko.observableArray();
        this.progress = ko.pureComputed(this._progress.bind(this));
        this.loaded = ko.observable(false);
        this.loggedIn = ko.observable(false);
        this.showExisting = ko.observable(false);
        this.canAdd = ko.pureComputed(this._canAdd.bind(this));
        this.options = options || {};
        this.subfolder = this.options.subfolder || '';
        this.ssoLoginLink = ssoLocation + '/accounts/login/?next=' + window.location

        this.deleteConfirmationDialog = new ConfirmationDialogVM();

        this.files = ko.pureComputed(function() {
            return this.showExisting() ? this.existingFiles() : this.uploadFiles();
        }.bind(this));

        this._checkLoginState().done(function() {
            if (this.options.supportsExisting) {
                this._loadExistingFiles();
            } else {
                this.loaded(true);
            }
        }.bind(this));
    }

    UploadFilesViewModel.prototype._checkLoginState = function() {
        var self = this;
        return $.ajax({
            url: ssoLocation + "/api/v2/public/users/@current/",
            type: "GET",
            contentType: "application/json",
            dataType: "json",
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            beforeSend: function(xhr) {
                xhr["withCredentials"] = true;
            }
        }).done(function() {
            self.loggedIn(true);
        }).fail(function() {
            self.loaded(true);
            self.loggedIn(false);
        });
    };

    UploadFilesViewModel.prototype._loadExistingFiles = function(fileName) {
        var self = this;
        var url = uploadLocation + "/api/list"

        if (this.subfolder || fileName) {
            url = url + '?subfolder=' + encodeURIComponent(this.subfolder + (fileName || ''))
        }

        return $.ajax({
            url: url,
            type: "GET",
            contentType: "application/json",
            dataType: "json",
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            beforeSend: function(xhr) {
                xhr["withCredentials"] = true;
            }
        }).done(function(result) {
            ko.utils.arrayForEach(result.files, function(file) {
                var extension = file.filename.substring(file.filename.lastIndexOf(".") + 1);
                self.existingFiles.push(new UploadFileViewModel({
                    name: file.filename,
                    type: plupload.mimeTypes[extension] || "",
                    size: file.size,
                    modified: file.modified,
                    status: 'existing',
                    etag: file.etag,
                    aws_settings: {
                        friendly_name: file.friendly_name,
                        fields: { key: file.key }
                    }
                }));
            });

            self.loaded(true);
        }).fail(function() {
            console.warn("Failed to load existing uploads");
        });
    };

    UploadFilesViewModel.prototype._progress = function() {
        var max = this.uploadFiles().length * 100.0;
        if (max === 0) {
            return 0;
        }

        var total = this.uploadFiles().reduce(function(result, current) {
            return result + current.progress();
        }, 0.0);

        return total / max * 100.0;
    };

    UploadFilesViewModel.prototype._files = function() {

    };

    UploadFilesViewModel.prototype._canAdd = function() {
        if (!this.options.allowedNumberOfFiles) {
            return true;
        }
        return this.uploadFiles().length < this.options.allowedNumberOfFiles;
    }

    UploadFilesViewModel.prototype._hasFiles = function() {
        return !!this.uploadFiles().length;
    }

    UploadFilesViewModel.prototype.add = function(file) {
        if (this.canAdd()) {
            this.uploadFiles.push(new UploadFileViewModel(file));
        }
    };

    UploadFilesViewModel.prototype.remove = function (file, forcedByError) {
        if (ko.unwrap(this.options.skipDeleteConfirmation) || ko.unwrap(forcedByError)) {
            this._remove(file)
            return
        }

        this.deleteConfirmationDialog.setFileName(file.name());
        this.deleteConfirmationDialog.show()
            .done(this._remove.bind(this, file))
    }

    UploadFilesViewModel.prototype._remove = function(file) {
        var statusBefore = file.file.status;
        if (statusBefore === plupload.UPLOADING || statusBefore === plupload.STARTED) {
            this.uploader.plup.stop();
        }

        this.uploader.plup.removeFile(file.file);
        this.uploadFiles.remove(file);
        if (file.status() === 'existing' || file.status() === plupload.DONE) {
            this._removeExisting(file);
        }

        if (statusBefore === plupload.UPLOADING || statusBefore === plupload.STARTED) {
            this.uploader.start();
        }
    };

    UploadFilesViewModel.prototype.removeExisting = function (file) {
        this.deleteConfirmationDialog.setFileName(file.name());
        this.deleteConfirmationDialog.show()
            .done(this._removeExisting.bind(this, file))
    }

    UploadFilesViewModel.prototype._removeExisting = function(file) {
        var self = this;
        return $.ajax({
            url: uploadLocation + "/api/delete/?key=" + file.key(),
            type: "DELETE",
            contentType: "application/json",
            dataType: "json",
            crossDomain: true,
            xhrFields: {
                withCredentials: true
            },
            beforeSend: function(xhr) {
                xhr["withCredentials"] = true;
            }
        }).done(function() {
            self.existingFiles.remove(file);
        }).fail(function() {
            console.warn("Failed to delete the file");
        });
    };

    UploadFilesViewModel.prototype.start = function() {
        if (this.files().length < 1) {
            return;
        }

        this.uploader.start();
    };

    UploadFilesViewModel.prototype.update = function(file) {
        var found = ko.utils.arrayFirst(this.uploadFiles(), function(fileVM) {
            return fileVM.file === file;
        });

        if (found) {
            found.update();
        }
    };

    return UploadFilesViewModel;
})();


var FormFieldUploadFileVM = (function() {
    function FormFieldUploadFileVM(uploader, options) {
        this.uploadFilesVM = new UploadFilesViewModel(uploader, options);
        this.uploader = uploader;
        this.fieldName = options.fieldName || "";
        this.buttonText = options.buttonText || "Upload File";
        this.skipDeleteConfirmation = options.skipDeleteConfirmation || false;
        this.hasFile = ko.pureComputed(this._hasFile.bind(this));
        this.fileKey = ko.pureComputed(this._fileKey.bind(this));

        // External fields
        this.externalResult = options.externalResult || ko.observable();
        this.externalCleanTrigger = options.externalCleanTrigger || ko.observable(false);

        this.externalCleanTrigger.subscribe(function (value) {
            if (value) {
                // empty files list without deleting the files from S3
                this.uploadFilesVM.uploadFiles.removeAll();
                // removes files from the queue
                this.uploader.plup.splice();
            }
        }.bind(this));

        // Assure to call plup.refresh() on every browser button resize in order for the inner '.moxie-shim' element to
        // correctly update
        const resizeObserver = new ResizeObserver(() => {
            uploader.plup.refresh()
        });

        resizeObserver.observe(uploader.options.browse_button);
    }

    FormFieldUploadFileVM.prototype._hasFile = function() {
        return this.uploadFilesVM.files().length === 1;
    }

    FormFieldUploadFileVM.prototype._fileKey = function() {
        var key = this._hasFile() ? this.uploadFilesVM.files()[0].key() : "";
        this.externalResult(key);
        return key;
    }

    return FormFieldUploadFileVM;
})();


var ConfirmationDialogVM = (function() {
    function ConfirmationDialogVM(options) {
        options = options || {};
        this.fileName = ko.observable("selected file");
        this.visible = ko.observable(false);
        this.saveDeffered = null;
    }

    ConfirmationDialogVM.prototype.setFileName = function(fileName) {
        this.fileName(fileName);
    }

    ConfirmationDialogVM.prototype.show = function() {
        this.saveDeffered = $.Deferred()
        this.visible(true);
        return this.saveDeffered.promise();
    }

    ConfirmationDialogVM.prototype.hide = function(result) {
        this.visible(false);
        return result ? this.saveDeffered.resolve() : this.saveDeffered.reject();
    }

    ConfirmationDialogVM.prototype.cancel = function () {
        this.hide(false);
    }

    return ConfirmationDialogVM;
})();

export { UploadFileViewModel, UploadFilesViewModel, ConfirmationDialogVM, FormFieldUploadFileVM }
