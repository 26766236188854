export const translations = {
    "Upload URL might be wrong or doesn't exist.":"URL de carga inexistente.",
    "Init error.":"Error de inicialización.",
    "%s specified, but cannot be found.":"%s especificado, pero no se puede encontrar",
    "Image format either wrong or not supported.":"Formato de imagen no soportada.",
    "HTTP Error.":"Error de HTTP.",
    "Error: File too large:":"Error: archivo demasiado grande:",
    "Duplicate file error.":"Error, archivo duplicado",
    "File size error.":"Error de tamaño de archivo.",
    "N/A":"No disponible",
    "Error: Invalid file extension:":"Error: Extensión de archivo inválida:",
    "%s already present in the queue.":"%s ya se encuentra en la lista.",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"Resolución fuera de límites! El tiempo de ejecución <b>%s</b> sólo admite imágenes hasta %wx%hpx",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"Se aceptan sólo %d archivo(s) al tiempo. Más, no se tienen en cuenta.",
    "Runtime ran out of available memory.":"No hay memoria disponible.",
    "File count error.":"Error en contador de archivos.",
    "File extension error.":"Error de extensión de archivo.",
}
