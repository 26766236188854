export const translations = {
    "Upload URL might be wrong or doesn't exist.":"URL:en va fel eller existerar inte.",
    "Init error.":"Problem vid initialisering.",
    "%s specified, but cannot be found.":"%s specificerad, men hittades inte.",
    "Image format either wrong or not supported.":"Bildformatet är fel eller så finns inte stöd för det.",
    "HTTP Error.":"HTTP problem.",
    "Error: File too large:":"Fel: Filen är för stor:",
    "Duplicate file error.":"Problem med dubbla filer.",
    "File size error.":"Problem med filstorlek.",
    "N/A":"N/A",
    "Error: Invalid file extension:":"Fel: Ej godkänd filändelse.",
    "%s already present in the queue.":"%s är redan tillagd.",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"Upplösning utanför gränserna! <b>%s</b> bara bilder upp till %wx%hpx stöds.",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"Det går bara lägga till %d filer åt gången, allt utöver detta togs bort.",
    "Runtime ran out of available memory.":"Slut på minne.",
    "File count error.":"Räknefel.",
    "File extension error.":"Problem med filändelse.",
}
