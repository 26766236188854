import { translations as bgTranslations } from './i18n/bg'
import { translations as daTranslations } from './i18n/da'
import { translations as deTranslations } from './i18n/de'
import { translations as enGbTranslations } from './i18n/en-gb'
import { translations as enTranslations } from './i18n/en'
import { translations as esSaTranslations } from './i18n/es-sa'
import { translations as esTranslations } from './i18n/es'
import { translations as fiTranslations } from './i18n/fi'
import { translations as frTranslations } from './i18n/fr'
import { translations as itTranslations } from './i18n/it'
import { translations as jaTranslations } from './i18n/ja'
import { translations as nbTranslations } from './i18n/nb'
import { translations as nlTranslations } from './i18n/nl'
import { translations as plTranslations } from './i18n/pl'
import { translations as ptBrTranslations } from './i18n/pt-br'
import { translations as ptTranslations } from './i18n/pt'
import { translations as svTranslations } from './i18n/sv'
import { translations as zhCnTranslations } from './i18n/zh-cn'

const uploadTranslations = {
    'bg': bgTranslations,
    'da': daTranslations,
    'de': deTranslations,
    'en-gb': enGbTranslations,
    'en': enTranslations,
    'es-sa': esSaTranslations,
    'es': esTranslations,
    'fi': fiTranslations,
    'fr': frTranslations,
    'it': itTranslations,
    'ja': jaTranslations,
    'nb': nbTranslations,
    'nl': nlTranslations,
    'pl': plTranslations,
    'pt-br': ptBrTranslations,
    'pt': ptTranslations,
    'sv': svTranslations,
    'zh-cn': zhCnTranslations,
};

export { uploadTranslations }
