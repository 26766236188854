import Url from 'url-parse'

var element = document.getElementById("upload-component-script")
var uploadLocation, ssoLocation

if (element) {
    uploadLocation = Url(element.src).origin
}

uploadLocation = uploadLocation || window.location.origin

switch (uploadLocation) {
    case 'https://upload.vectorworks.net':
        ssoLocation = 'https://sso.vectorworks.net'
        break
    case 'https://upload-staging.vectorworks.net':
        ssoLocation = 'https://sso-staging.vectorworks.net'
        break
    case 'https://upload-dev.vectorworks.net':
        ssoLocation = 'https://sso-dev.vectorworks.net'
        break
    case 'https://upload-test.vectorworks.net':
        ssoLocation = 'https://sso-dev.vectorworks.net'
        break
    default:
        uploadLocation = undefined
}

ssoLocation = ssoLocation || window.ssoLocation || 'https://sso.vectorworks.net'
uploadLocation = uploadLocation || window.uploadLocation || 'https://upload.vectorworks.net'

export { ssoLocation, uploadLocation }
