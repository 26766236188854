export const translations = {
    "Upload URL might be wrong or doesn't exist.":"アップロード先の URL が存在しません",
    "Init error.":"イニシャライズエラー",
    "%s specified, but cannot be found.":"指定された %s は見つかりません。",
    "Image format either wrong or not supported.":"画像形式が間違っているかサポートされていません",
    "HTTP Error.":"HTTP エラー",
    "Error: File too large:":"エラー: ファイルが大きすぎます:",
    "Duplicate file error.":"重複ファイルエラー",
    "File size error.":"ファイルサイズエラー",
    "N/A":"N/A",
    "Error: Invalid file extension:":"エラー: ファイルの拡張子が無効です：",
    "%s already present in the queue.":"%s 既にキューに存在しています",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"解像度がしきい値を超えています! ランタイム <b>%s</b> は縦 %h px 横 %w px までをサポートします",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"アップロード可能なファイル数は %d です 余分なファイルは削除されました",
    "Runtime ran out of available memory.":"ランタイムが使用するメモリが不足しました",
    "File count error.":"ファイル数エラー",
    "File extension error.":"ファイル拡張子エラー",
}
