export const translations = {
    "Upload URL might be wrong or doesn't exist.":"Lähetyksen URL-osoite saattaa olla väärä tai sitä ei ole olemassa.",
    "Init error.":"Init virhe.",
    "%s specified, but cannot be found.":"",
    "Image format either wrong or not supported.":"Kuvaformaatti on joko väärä tai ei tuettu.",
    "HTTP Error.":"HTTP-virhe.",
    "Error: File too large:":"Virhe: Liian suuri tiedosto:",
    "Duplicate file error.":"Tuplatiedostovirhe.",
    "File size error.":"Tiedostokokovirhe.",
    "N/A":"N/A",
    "Error: Invalid file extension:":"Virhe: Virheellinen tiedostopääte:",
    "%s already present in the queue.":"%s on jo jonossa.",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"Vain %d tiedosto(a) voidaan lähettää kerralla. Ylimääräiset tiedostot ohitettiin.",
    "Runtime ran out of available memory.":"Toiminnon käytettävissä oleva muisti loppui kesken.",
    "File count error.":"Tiedostolaskentavirhe.",
    "File extension error.":"Tiedostopäätevirhe.",
}
