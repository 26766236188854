export const translations = {
    "Upload URL might be wrong or doesn't exist.":"Upload URL is verkeerd of bestaat niet.",
    "Init error.":"Initialisatie error.",
    "%s specified, but cannot be found.":"",
    "Image format either wrong or not supported.":"bestandsextensie is verkeerd of niet ondersteund.",
    "HTTP Error.":"HTTP Error.",
    "Error: File too large:":"Error: Bestand te groot:",
    "Duplicate file error.":"Bestand bestaat al.",
    "File size error.":"Bestandsgrootte error.",
    "N/A":"Niet beschikbaar",
    "Error: Invalid file extension:":"Error: Ongeldige bestandsextensie:",
    "%s already present in the queue.":"%s is al aan de wachtrij toegevoegd.",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"Upload accepteert alleen %d bestand(en) tegelijk. Extra bestanden zijn verwijderd.",
    "Runtime ran out of available memory.":"Het maximum bruikbare geheugen is overschreden.",
    "File count error.":"Teveel bestand(en) error.",
    "File extension error.":"Ongeldig bestandsextensie.",
}
