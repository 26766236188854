export const translations = {
    "Upload URL might be wrong or doesn't exist.":"L'URL d'envoi est soit erronée soit n'existe pas.",
    "Init error.":"Erreur d'initialisation.",
    "%s specified, but cannot be found.":"%s spécifié, mais ne peut pas être trouvé.",
    "Image format either wrong or not supported.":"Le format d'image est soit erroné soit pas géré.",
    "HTTP Error.":"Erreur HTTP.",
    "Error: File too large:":"Erreur: Fichier trop volumineux:",
    "Duplicate file error.":"Erreur: Fichier déjà sélectionné.",
    "File size error.":"Erreur de taille de fichier.",
    "N/A":"Non applicable",
    "Error: Invalid file extension:":"Erreur: Extension de fichier non valide:",
    "%s already present in the queue.":"%s déjà présent dans la file d'attente.",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"Résolution sur les frontières ! L'exécution de <b>%s</b> supporte seulement les images de %wx%hpx",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"Que %d fichier(s) peuvent être envoyé(s) à la fois. Les fichiers supplémentaires ont été ignorés.",
    "Runtime ran out of available memory.":"Le traitement a manqué de mémoire disponible.",
    "File count error.":"Erreur: Nombre de fichiers.",
    "File extension error.":"Erreur d'extension de fichier",
}
