export const translations = {
    "Upload URL might be wrong or doesn't exist.":"Adres URL może być nieprawidłowy lub może nie istnieć",
    "Init error.":"Błąd inicjalizacji.",
    "%s specified, but cannot be found.":"%s określony ale nie można znaleźć.",
    "Image format either wrong or not supported.":"Format zdjęcia jest zły lub nieobsługiwany",
    "HTTP Error.":"Błąd HTTP.",
    "Error: File too large:":"Błąd: Plik za duży:",
    "Duplicate file error.":"Błąd: duplikacja pliku.",
    "File size error.":"Plik jest zbyt duży.",
    "N/A":"Nie dostępne",
    "Error: Invalid file extension:":"Błąd: Nieprawidłowe rozszerzenie pliku:",
    "%s already present in the queue.":"%s już występuje w kolejce.",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"Za duża rozdzielczość! <b>%s</b> maksymalna rozdzielczość to: %wx%hpx.",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"Można jednocześnie dodać %d plików. Dodatkowe pliki zostały usunięte.",
    "Runtime ran out of available memory.":"Wyczerpano pamięć RAM.",
    "File count error.":"Błąd liczenia pliku.",
    "File extension error.":"Nie obsługiwany format pliku.",
}
