export const translations = {
    "Upload URL might be wrong or doesn't exist.":"URL de envio incorreta ou inexistente",
    "Init error.":"Erro ao iniciar.",
    "%s specified, but cannot be found.":"Método de envio <b>%s</b> especificado, mas não pôde ser encontrado.",
    "Image format either wrong or not supported.":"Imagem em formato desconhecido ou não permitido.",
    "HTTP Error.":"Erro HTTP.",
    "Error: File too large:":"Erro: Arquivo muito grande:",
    "Duplicate file error.":"Erro: Arquivo duplicado.",
    "File size error.":"Tamanho de arquivo não permitido.",
    "N/A":"N/D",
    "Error: Invalid file extension:":"Erro: Extensão de arquivo inválida:",
    "%s already present in the queue.":"%s já presentes na fila.",
    "Resoultion out of boundaries! <b>%s</b> runtime supports images only up to %wx%hpx.":"Resolução fora de tamanho. O método de envio <b>%s</b> suporta imagens com no máximo %wx%hpx.",
    "Upload element accepts only %d file(s) at a time. Extra files were stripped.":"Só são aceitos %d arquivos por vez. O que passou disso foi descartado.",
    "Runtime ran out of available memory.":"Método de envio ficou sem mem\\u00f3ria.",
    "File count error.":"Erro na contagem dos arquivos",
    "File extension error.":"Tipo de arquivo não permitido.",
}
